import _ from 'lodash';
import { Vue, Component, Watch, Inject } from 'vue-property-decorator';
import Sidebar from './Sidebar.vue';
import { GroupItem, Link } from './sidebar';
import { ROUTE_FEATURES_NAME } from '@/routes/root';

@Component({
  components: {
    Sidebar,
  },

  head() {
    return {
      title: this.$t('features.title').toString(),
    };
  },

  async beforeRouteEnter(to, from, next) {
    const key = to.params.key;
    if (!!!key) {
      return next({
        name: ROUTE_FEATURES_NAME,
        params: { key: 'tracking-the-expense--introduction' },
        replace: true,
      });
    }

    next();
  },

  created(this: Features) {
    if (this.$isServer) return;
    if (this.isLocateAreEn) return;

    window.location.replace('https://doc.moze.app')
  },
})
export default class Features extends Vue {
  @Inject('baseLayout__setSidebarList')
  setSidebarList!: (sidebars: GroupItem[]) => void;

  get isLocateAreEn() {
    return this.$i18n.locale === 'en'
  }

  get sidebars(): GroupItem[] {
    const _categories = [['tracking-the-expense', [
      'introduction',
      'multi-currency-support',
      'fee-and-discount',
      'transaction-reminder',
      'postpone-transaction',
      'recurring-event',
      'installment',
      'transfer',
      'receivables',
      'payables',
      'refund',
      'complete-gesture',
      'collection',
      'continue-recording',
      'template',
    ]], ['account', [
      'introduction',
      'credit-account',
      'credit-sharing',
      'statement-interval',
      'auto-deposit',
      'balance-included',
      'receivables-payables',
      'statement-mode',
      'adjust-balance',
      'statement-payment',
      this.$i18n.locale !== 'en'
        ? 'combine-account' : undefined,
      this.$i18n.locale !== 'en'
        ? 'statement-installment' : undefined,
      'bonus-rewards',
    ].filter((s) => {
      return !!s;
    })], ['project', [
      'introduction',
      'statistic-project',
      'budget',
    ]], ['report', [
      'introduction',
    ]], ['notifications', [
      'introduction',
    ]], ['search', [
      'introduction',
      'batch-edit-and-delete',
    ]], ['backup-restore', [
      'introduction',
    ]], ['import-export', [
      'import',
      'export',
    ]], this.$i18n.locale === 'tw' ? ['e-invoice', [
      'introduction',
      'carrier',
      'invoice-link',
      'winning-list',
    ]] : undefined];

    const groups: GroupItem[] = [];

    for (let i = 0; i < _categories.length; i++) {
      const category = _categories[i];
      if (!category) { continue; }

      const name = category[0];
      const files = category[1];
      const links: Link[] = [];

      for (let j = 0; j < files.length; j++) {
        const file = files[j];
        links.push({
          label: `categories.${name}.files.${file}`,
          to: {
            name: ROUTE_FEATURES_NAME,
            params: { key: `${name}--${file}` },
          },
        });
      }

      groups.push({
        title: `categories.${name}.name`,
        collapse: true,
        links,
      });
    }

    return groups;
  }

  content: string | null = null;

  get filePath(): string | void {
    if (_.isNil(this.$route.params.key)) {
      return;
    }
    if (_.isNil(this.$i18n.locale)) {
      return;
    }

    const paths = this.$route.params.key.split('--');
    paths.push(paths[paths.length - 1]);

    return `${this.$i18n.locale}/${paths.join('/')}.md`;
  }

  @Watch('filePath', { immediate: true })
  async handleFilePathChange(val: string) {
    if (_.isNil(val)) {
      return;
    }

    const { default: content } = await import(`@/assets/docs/features/${val}`);
    if (!content) {
      return;
    }

    this.content = content;
  }

  @Watch('sidebars', { immediate: true })
  handleSidebarListChange(val: GroupItem[]) {
    this.setSidebarList(val);
  }
}
